import { Box, SxProps, Theme, Typography } from '@mui/material';
import { Warning } from 'src/components/primitives/Warning';
import { TxErrorType } from 'src/ui-config/errorMapping';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export const GasEstimationError = ({ txError, sx, onClose }: {
  txError: TxErrorType;
  sx?: SxProps<Theme>;
  onClose?: () => void;
}) => (
  <Warning
    severity="error"
    sx={{
      position: 'relative',
      mt: 4,
      mb: 0,
      py: 2.5,
      px: 4,
      borderRadius: '8px !important',
      border: '1px solid rgba(199, 0, 70, 0.32)',
      background: 'rgba(199, 0, 70, 0.16)',
      ...sx
    }
    }>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
      {txError.error ? (
        <Typography variant="description">{txError.error}</Typography>
      ) : (
        <Typography variant="description">There was some error. Please try changing the parameters or</Typography>
      )}
      <Typography
        variant="description"
        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        onClick={() => navigator.clipboard.writeText(txError.rawError.message.toString())}
      >
        Copy the error
      </Typography>
      <Box sx={{ position: 'absolute', right: '6px', top: '6px', cursor: 'pointer' }} onClick={onClose}>
        <CloseRoundedIcon />
      </Box>
    </Box>
  </Warning>
);
